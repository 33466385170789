import { LogoStyled } from './ThamesCCWholesaleLogo.style';
import ThamesCC from 'image/wholesale/thames-cc.png';

export type ThamesCCWholesaleLogoProps = {
  className?: string;
};

const ThamesCCWholesaleLogo: React.FC<ThamesCCWholesaleLogoProps> = ({
  className,
}) => {
  return (
    <LogoStyled className={className}>
      <img src={ThamesCC} alt="Thames Cash & Carry Logo" width={317} height={88} />
    </LogoStyled>
  );
};

export default ThamesCCWholesaleLogo;
