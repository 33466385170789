import styled from 'styled-components';
import { Button, IconButton, Module } from 'styles/Global';
import RHWholesaleLogo from './RHWholesaleLogo';

export const Header = styled.div`
  h1 {
    margin: 48px 24px 12px;
  }
`;

export const Wholesalers = {
  Grid: styled.div`
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      grid-template-columns: unset;
      grid-template-rows: unset;
    }
  `,
  Link: styled.a`
    display: block;
    width: auto;
    margin: 10px;

    &:hover {
      text-decoration: none;
      & > div {
        box-shadow: var(--devo-heavy-shadow);
      }
    }

    @media screen and (max-width: 767px) {
      width: auto;
    }
  `,
  Container: styled.div`
    display: block;
    width: auto;
    margin: 10px;
    @media screen and (max-width: 767px) {
      width: auto;
    }
  `,
  Item: styled(Module)`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    padding: 28px 20px;
    text-align: center;

    &.no-p {
      padding: 0;
      overflow: hidden;
    }
  `,
  Customer: styled.button`
    margin: 4px auto;
    padding: 4px 16px;
    text-align: center;
    background-color: transparent;
    text-decoration: none;
    border: none;
    width: fit-content;
    border-radius: 999px;
    font-size: 14px;
    color: var(--secondary-label-color);
    transition: background-color 0.1s;

    &:hover {
      background-color: var(--secondary-background-color);
    }
  `,
  Button: styled(IconButton)`
    width: max-content;
    margin: 16px auto 0;
    padding: 8px 16px;
    border-radius: 999px;
    &:hover {
      transform: none;
    }
  `,
};

export const RHWholesaleLogoStyled = styled(RHWholesaleLogo)`
  background-size: 100% 100%;
`;
