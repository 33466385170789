import usePermissions from 'endpoints/permissions/usePermissions';
import React from 'react';
import styled, { StyledFunction } from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';
import { AvailabilityEnum, Availability } from 'models/Product';
import {
  availabilityColor,
  availabilityDescription,
  AVAILABILITY_ENUM_ALL_CASES,
} from 'helper/product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const backgroundColor = (status: Availability): string => {
  switch (status) {
    case 'AVAILABLE':
      return 'var(--devo-green)';
    case 'NOT_AVAILABLE':
      return 'var(--devo-orange)';
    case 'REMOVED':
      return 'var(--devo-removed-gray)';
    case 'DRAFT':
      return '#aaaaaadd';
  }
  return '#000';
};

const Wrapper = styled.div`
  button,
  button:active,
  button:focus {
    color: var(--white) !important;
    background-color: ${(props) =>
      backgroundColor(props.color as Availability)} !important;
    box-shadow: none;
    border: none;
    border-radius: 1rem;
    font-size: 13px;
    font-weight: 600;
  }

  button.draft {
    pointer-events: none;
    padding: 6px 28px;
  }

  button:hover {
    opacity: 0.8;
    box-shadow: none;
  }

  .dropdown-toggle::after {
    margin-left: 0.4em;
  }

  @media (max-width: 1024px) {
    button,
    button:active,
    button:focus {
      color: var(--white) !important;
      background-color: ${(props) =>
        backgroundColor(props.color as Availability)} !important;
      box-shadow: none;
      border: none;
      font-size: 13px;
      font-weight: 600;
      width: auto;
      height: auto;
      margin-bottom: 15px;
      padding: 5px 15px;
      border-radius: 16px;
    }

    &.post-scan button {
      margin-bottom: 0;
    }
  }
`;

type StatusProps = {
  value: AvailabilityEnum;
  onSelect: (value: AvailabilityEnum) => void;
  alignRight?: boolean;
  postScan?: boolean;
  isLive?: boolean;
};

const Status: React.FC<StatusProps> = ({
  value,
  onSelect,
  alignRight = false,
  postScan,
  isLive = true,
}) => {
  const { permissions } = usePermissions();
  let enabled = permissions.write_shop_product;

  const handleClick = (e: any, option: AvailabilityEnum) => {
    e.preventDefault();
    onSelect(option);
  };

  const statuses: AvailabilityEnum[] = ['AVAILABLE', 'NOT_AVAILABLE'];

  if (!enabled)
    return (
      <Wrapper color={value} className="status">
        <button>{availabilityDescription(value)}</button>
      </Wrapper>
    );

  return (
    <Wrapper
      color={!isLive ? 'DRAFT' : value}
      className={'status' + postScan ? ' post-scan' : ''}
    >
      <Dropdown align={alignRight ? 'end' : 'start'}>
        {isLive ? (
          <Dropdown.Toggle>{availabilityDescription(value)}</Dropdown.Toggle>
        ) : (
          <button className="draft">Draft</button>
        )}
        {isLive && (
          <Dropdown.Menu>
            {statuses.map((option: AvailabilityEnum, index: number) => (
              <Dropdown.Item
                key={index}
                id={option}
                onClick={(e) => handleClick(e, option)}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  color={availabilityColor(option)}
                />
                &nbsp;
                {availabilityDescription(option)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </Wrapper>
  );
};

export default Status;
