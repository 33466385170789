import { type } from 'os';
import { Category, ShopCategory } from './Category';
import { PlatformType } from './Order';
import { Conditional } from 'helper/types/conditional';
import { ObjectValues } from 'helper/types/ObjectValues';

export const PRODUCTS_GQL_PROPERTIES = `
  shopProducts
  results
`;

export const PRODUCT_GQL_PROPERTIES = `
  uuid
  availability
  devoPrice
  limit
  popularityScore
  product
  shopCategory
  shopPrice
  shopUuid
  vatRate
`;

export interface ProductsResponse {
  products: {
    shopProducts: ShopProduct[];
    results: number;
  };
}

/**
 * @deprecated Use Availability instead
 */
export type AvailabilityEnum = 'AVAILABLE' | 'NOT_AVAILABLE' | 'REMOVED';

export type Availability = AvailabilityEnum | 'DRAFT';

export type QuantityMeasureEnum =
  | 'MILLIGRAMS'
  | 'GRAMS'
  | 'KILOGRAMS'
  | 'CENTILITRES'
  | 'MILLILITRES'
  | 'LITRES';

export type NullableQuantityMeasureEnum = QuantityMeasureEnum | '';

export const quantityMeasureEnum = {
  milligrams: 'MILLIGRAMS',
  grams: 'GRAMS',
  kilograms: 'KILOGRAMS',
  centilitres: 'CENTILITRES',
  millilitres: 'MILLILITRES',
  litres: 'LITRES',
} as const satisfies Record<string, QuantityMeasureEnum>;

export const quantityMeasures = [
  'GRAMS',
  'KILOGRAMS',
  'CENTILITRES',
  'MILLILITRES',
  'MILLIGRAMS',
  'LITRES',
] as const satisfies readonly QuantityMeasureEnum[];

export interface ShopProduct {
  uuid: string;
  shopUuid: string;
  product: Product;
  shopCategory: ShopCategory;
  shopPrice: number;
  devoPrice: number;
  popularityScore: number;
  availability: AvailabilityEnum;
  vatRate: number;
  limit: number;
}

export interface ShopProductResponse {
  product: ShopProduct;
}

export interface Product {
  upc: number;
  name: string;
  prettyId?: string;
  units: number;
  quantityUnits: number;
  quantityMeasure: QuantityMeasureEnum;
  category: Category;
  vatRate: number;
  thumbnail: string;
  image: string;
  live: boolean;
  priceMarked: boolean;
}

export const PRODUCT_UPDATE_RESPONSE_GQL_PROPERTIES = `
  availabilityText
  limit
  popularityScore
  shopPrice
  shopUuid
  upc
`;

export interface UpdateShopProductResponse {
  product: ProductUpdateResponse;
}

export interface ProductUpdateResponse {
  authorUuid: string;
  availabilityText: string;
  limit: number;
  popularityScore: number;
  shopPrice: number;
  shopUuid: number;
  upc: number;
  uuid: string;
}

export interface ProductUpdatePayload {
  productUpc: number;
  shopId: string;
  input: ShopProductDBO;
}

export type ShopProductDBO = Partial<ProductUpdateResponse>;

export type GetProductsByElasticSearchResponse = {
  products: {
    shopProducts: ShopProduct[];
    results: number;
  };
};

export type GetProductsByElasticSearchPayload = {
  shopId: string;
  keyword: string;
};

export type GetShopProductMarginPayload = {
  uuid: string;
  costPerProduct?: number;
  shopPrice?: number;
} & Conditional<{
  costPerPack: number;
  itemsPerPack: number;
}>;

export type ProductMargin = {
  price: number;
  markup: number;
  commission: number;
  prePerCommission: boolean;
  platformPrice: number;
  gross: number;
  grossMargin: number;
  cost: number;
  profit: number;
  profitMargin: number;
};

export type ShopProductMarginData = {
  uuid: string;
  shopPrice: number;
  productCost: {
    uuid: string;
    packSize: number;
    packPrice: number;
    cost: number;
  };
  margin: ProductMargin;
  platformMargins: Record<PlatformType, ProductMargin>;
};

export type GetShopProductMarginResponse = {
  response: ShopProductMarginData;
};

export type SaveShopProductMarginPayload = {
  input: {
    uuid: string;
    packSize: number;
    packPrice: number;
    cost: number;
  };
};

export type SaveShopProductMarginResponse = GetShopProductMarginResponse;

export type GetProductsPeriodicAvailabilityPayload = {
  shopUuid: string;
  input: number[];
};

export const availabilityDaysEnum = {
  monday: 'MONDAY',
  tuesday: 'TUESDAY',
  wednesday: 'WEDNESDAY',
  thursday: 'THURSDAY',
  friday: 'FRIDAY',
  saturday: 'SATURDAY',
  sunday: 'SUNDAY',
} as const;

export type AvailabilityDay = ObjectValues<typeof availabilityDaysEnum>;

export const availabilityDays = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
] as const satisfies readonly AvailabilityDay[];

type PeriodicAvailabilityProduct = {
  shopUuid: string;
  productUpc: number;
  availableDays: AvailabilityDay[];
};

export type GetProductsPeriodicAvailabilityResponse = {
  response: {
    results: PeriodicAvailabilityProduct[];
    total: number;
  };
};

export type PutProductsPeriodicAvailabilityPayload = {
  input: PeriodicAvailabilityProduct;
};

export type PutProductsPeriodicAvailabilityResponse = never;
