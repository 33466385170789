import { Rename } from 'helper/types/rename';
import { Availability, AvailabilityEnum, QuantityMeasureEnum } from './Product';

export const POS_PRODUCT_PROPERTIES = `
  availability
  upc
  shopUuid
  name
  units
  quantityUnits
  quantityMeasure
  quantityUnitsPricing
  quantityMeasurePricing
  categoryId
  vatCode
  thumbnailUrl
  imageUrl
  rsp
  shopPrice
  createdAt
  isLive
  priceMarked
`;

export interface PosProduct {
  uuid: string;
  availability: AvailabilityEnum;
  upc: number;
  shopUuid: string;
  name: string;
  units: number;
  quantityUnits: number;
  quantityUnitsPricing: number | null;
  quantityMeasurePricing: QuantityMeasureEnum;
  quantityMeasure: QuantityMeasureEnum;
  categoryId: number;
  vatCode: number;
  thumbnailUrl: string;
  imageUrl: string;
  rsp: number;
  shopPrice: number;
  createdAt: string;
  isLive: boolean;
  priceMarked: boolean;
}

export type UnstandardizedPosProduct = Rename<
  PosProduct,
  'priceMarked',
  'isPriceMarked'
>;

export default PosProduct;

export type PostPosInventoryProductsPayload = {
  query: string;
  input: any;
};

export type PostPosInventoryProductsQueryString = {
  shopUuid: string;
  keyword?: string;
  lastUpdated?: number;
  lastUpdatedUntil?: number;
  categoryId?: number;
  includeSubcats?: boolean;
  availabilityList?: Availability[];
  isPriceMarked?: boolean;
  isLive?: boolean;
  vatCode?: number;
  fromShopPrice?: number;
  toShopPrice?: number;
  fromRSP?: number;
  toRSP?: number;
  quantityMeasure?: QuantityMeasureEnum;
  fromQuantity?: number;
  toQuantity?: number;
  fromUnits?: number;
  toUnits?: number;
  limit?: number;
  offset?: number;
};

export type PostPosInventoryProductsResponse = {
  products: {
    results: PosProduct[];
    total: number;
  };
};

export type GetPosProductPayload = {
  shopUuid: string;
  productUpc: number;
};

export type GetPosProductResponse = {
  product: PosProduct;
};
